<template>
  <div>
    <!-- TABLE TOP -->
    <app-data-table-top
      v-if="topTable"
      :per-page="itemsPerPage"
      :table-name="tableName"
      v-bind="topTable"
      v-on="{ ...omitBy($listeners, (_, event) => !event.includes('table-') || event === 'table-search-text') }"
      @table-select-per-page="itemsPerPage = $event"
      @table-search-filter="$set(params, 'searchKey', $event)"
      @table-search-text=";(searchText = $event || null), $emit('table-search-text', searchText)"
    >
      <!-- Load all app-data-table-top's scoped slots to be used from parent -->
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </app-data-table-top>

    <!-- TABLE -->
    <b-overlay :show="overlay" no-fade>
      <b-table
        ref="bTable"
        :class="[`position-relative ${tableClass}`, { 'table-dropdown-action': computedDisplayMode === 'dropdown' }]"
        :current-page="!manualPagination ? currentPage : 1"
        :empty-text="!overlay ? $t('common.no_record_found') : ''"
        :fields="computedFields"
        :filter="searchText"
        :hover="hover"
        :items="typeof items === 'function' ? localItems : items"
        :per-page.sync="itemsPerPage"
        :primary-key="primaryKey"
        responsive
        v-bind="$attrs"
        v-on="$listeners"
        @refreshed.once="isLoadedFirstTime = true"
      >
        <!-- Load all b-table's scoped slots to be used from parent -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>

        <!-- Loading -->
        <template v-if="loaderOnRefresh || !isLoadedFirstTime" #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- Head: Actions -->
        <template v-if="actionsTable" #head(actions)>{{ actionsTitle || $tc('action.title', 2) }}</template>

        <!-- Column: Actions -->
        <template v-if="actionsTable" #cell(actions)="row">
          <app-data-table-actions
            :display-mode="computedDisplayMode"
            :show-delete="actionValidation('showDelete', row.item)"
            :show-duplicate="actionValidation('showDuplicate', row.item)"
            :show-edit="actionValidation('showEdit', row.item)"
            :show-show="actionValidation('showShow', row.item)"
            :table-name="`${tableName}-${row.item[primaryKey]}`"
            v-bind="actionsTable"
            @add="$emit('add', row)"
            @cancel="$emit('cancel', row)"
            @delete="$emit('delete', row)"
            @duplicate="$emit('duplicate', row)"
            @edit="$emit('edit', row)"
            @save="$emit('save', row)"
            @show="$emit('show', row)"
          />
        </template>
      </b-table>
    </b-overlay>

    <!-- TABLE BOTTOM -->
    <app-data-table-bottom
      v-if="bottomTable"
      :page="currentPage"
      :per-page="itemsPerPage"
      :table-name="tableName"
      class="mx-2 mb-2"
      v-bind="bottomTable"
      @pagination="currentPage = $event"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { omitBy } from 'lodash'

import AppDataTableActions from '@/components/AppDataTableActions.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableTop from '@/components/AppDataTableTop.vue'

/**
 * Component to uniformize all data-table with top and pagination section
 * @Important For items <Array> paginable with api, provide `manual-pagination` prop
 *
 * @example
 * <app-data-table
 *  ref="refProductsListTable"
 *  table-name="product-list"
 *  :fields="tableColumns"
 *  :items="fetchServices"
 *  :small="$store.getters['app/xlAndDown']"
 *  :top-table="{
 *    showAdd: $can('SERVICE_ADD'),
 *    showSearch: true,
 *    searchFilterOptions,
 *  }"
 *  :actions-table="{
 *    showEdit: $can('SERVICE_EDIT'),
 *    showDelete: item => $can('SERVICE_DELETE') && item.validate === true,
 *  }"
 *  :bottom-table="{ totalCount: totalProducts }"
 *  .@table-add="$router.push({ name: 'serviceAdd' })"
 *  .@delete="deleteService($event.item.id)"
 *  .@edit="$router.push({ ... })"
 * >
 *  <!-- This component doesn't modify the slot's <b-table /> -->
 *  <!-- Slot for column title (usually useless if `label` in object into `fields`) -->
 *  <template #head(KEY)="{ label }">
 *    {{ label }}
 *  </template>
 *  <!-- Slot for cell content -->
 *  <template #cell(KEY)="{ item }">
 *    <b>{{ item }}</b>
 *  </template>
 * </app-data-table>
 */
export default defineComponent({
  name: 'AppDataTable',

  components: {
    AppDataTableTop,
    AppDataTableBottom,
    AppDataTableActions,
  },

  props: {
    tableName: {
      type: String,
      default: '',
    },
    tableClass: {
      type: String,
      default: '',
    },
    primaryKey: {
      type: String,
      default: 'id',
    },
    /**
     * Fields to display on table
     */
    fields: {
      type: [Array, Function],
      default: () => [],
    },
    items: {
      type: [Array, Function],
      default: undefined,
    },
    // For default value of page / perPage, see `ref` above
    page: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: null,
    },
    manualPagination: {
      type: Boolean,
      default: false,
    },
    allItems: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    // ? Never use it and prefer the default mode ?
    loaderOnRefresh: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    topTable: {
      type: Object,
      default: null,
    },
    actionsTable: {
      type: Object,
      default: null,
    },
    bottomTable: {
      type: Object,
      default: null,
    },
    actionsTitle: {
      type: String,
      default: null,
    },
  },

  setup(props, ctx) {
    const { _cloneDeep, $set, $i18n } = ctx.root

    // <b-table /> ref template
    const bTable = ref(null)

    // Useful to display the loader on first fetch api
    const isLoadedFirstTime = ref(false)

    // Data to manage the table (order, filter, pagination, etc)
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const params = ref({})
    const searchText = ref(null)
    const isFirstFetch = ref(true)

    watch(
      [() => props.page, () => props.perPage],
      ([propPage, propPerPage]) => {
        if (propPage) currentPage.value = propPage
        if (propPerPage) itemsPerPage.value = propPerPage
        else if (props.allItems) itemsPerPage.value = undefined
      },
      { immediate: true },
    )

    watch(
      () => props.overlay,
      overlay => {
        if (isFirstFetch.value && !overlay) isFirstFetch.value = false
      },
    )

    // ? Initialize searchKey
    if (props.topTable?.searchDefaultFilter) {
      $set(params.value, 'searchKey', props.topTable.searchDefaultFilter)
    } else if (props.topTable?.searchFilterOptions && props.topTable?.searchFilterOptions[0]) {
      $set(params.value, 'searchKey', props.topTable.searchFilterOptions[0].value)
    }

    // ? ORDER: Initialize the `${field.key}Order` to null for all columns who are sortable
    // Useful for simple/multi order
    props.fields.forEach(({ key, sortable }) => {
      if (sortable) $set(params.value, `${key}Order`, null)
    })

    // ? ITEMS: Run only if props.items is a function. Else, items's b-table prop = props.items <Array>
    const localItems = (btableMeta, callback) => {
      const copyBtableMeta = _cloneDeep(btableMeta)

      // Change all empty strings to null value. Useful for Axios (queryparams to null are not sent)
      copyBtableMeta.filter = copyBtableMeta.filter || null
      copyBtableMeta.sortBy = copyBtableMeta.sortBy || null

      // For management of the list directly from api. Classic b-table comportment.
      if (copyBtableMeta.sortBy) {
        // Transform boolean to 'asc' / 'desc' value (asked by api)
        const sortOrder = copyBtableMeta.sortDesc ? 'desc' : 'asc'

        // Format: params[`${field.key}Order`] = 'asc' (or 'desc')
        params.value[`${copyBtableMeta.sortBy}Order`] = sortOrder
      }

      // Inject the computedSort to original btableMeta, will be usable from parent component.
      copyBtableMeta.computedSort = params.value
      props.items(copyBtableMeta, callback)
    }

    // ? FIELDS
    const computedFields = computed(() => {
      if (!props.actionsTable) return props.fields

      // Apply a common style for `actions` column
      return [...props.fields, { key: 'actions', label: $i18n.t('common.actions'), thClass: 'text-center max-width-actions' }]
    })

    // ? DISPLAY-MODE: Comput the displayMode prop about the number of actions available
    const computedDisplayMode = computed(() => {
      // If true, computedDisplayMode is not used, but a return value is necessary
      if (!props.actionsTable) return null

      // Note: this component set all actions to false by default
      let nbActions = 0
      const actionsList = ['showSave', 'showEdit', 'showShow', 'showDelete', 'showDuplicate', 'showAdd', 'showCancel']
      actionsList.forEach(action => {
        if (props.actionsTable[action]) nbActions++
      })

      return nbActions > 2 ? 'dropdown' : 'inline-icon'
    })

    /**
     * To use bTable's ref from parent component, use `$refs.refOfAppDataTable.bTable.otherMethod()`
     * ex: $refs.refOfAppDataTable.refresh() or $refs.refOfAppDataTable.bTable.otherMethod() works
     * ex: $refs.refOfAppDataTable.refresh() works
     */
    const refresh = () => {
      bTable.value.refresh()
    }

    const actionValidation = (actionName, item) => {
      if (typeof props.actionsTable[actionName] === 'function') {
        return props.actionsTable[actionName](item) || false
      }

      return props.actionsTable[actionName] || false
    }

    return {
      bTable,
      params,
      isFirstFetch,
      isLoadedFirstTime,
      refresh,
      computedFields,
      computedDisplayMode,
      currentPage,
      itemsPerPage,
      searchText,
      localItems,
      omitBy,
      actionValidation,
    }
  },
})
</script>

<style lang="scss">
.max-width-actions {
  max-width: 130px;
  width: 130px;
}
</style>
